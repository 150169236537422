<template>
  <q-page class="flex flex-center">
    <q-card class="my-card">
      <q-card-section>
        <div class="text-h6">Login Options</div>
        <!-- <div class="text-subtitle2">Choose an option</div> -->
      </q-card-section>

      <q-separator />

      <q-card-actions vertical>
        <q-btn 
          :outline="false" 
          :flat="true" 
          padding="none"
          @click="googleSignIn()"
        >
          <img src="@/assets/btn_google_signin_light_normal_web.png">
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-page>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

const auth = getAuth()
const provider = new GoogleAuthProvider()

provider.setCustomParameters({
  'login_hint': 'user@example.com'
});

export default ({
  name: 'login',
  methods: {
    googleSignIn: function() {
      signInWithPopup(auth, provider)
        .then((result) => {
          // implementation from - https://firebase.google.com/docs/auth/web/google-signin#web-version-9_4
          const credential = GoogleAuthProvider.credentialFromResult(result)
          const token = credential.accessToken
          const user = result.user
          this.$store.state.user = user
          console.log(`Google sign success. User: ${user}; Token: ${token}`)
          this.$router.push('loggedin')
        }).catch((error) => {
          const errorCode = error.code
          const errorMessage = error.message
          const email = error.email
          console.log(`Google sign in error. Code: ${errorCode}; Message: ${errorMessage}; Email: ${email}`)
        })
    }
  }
})
</script>
