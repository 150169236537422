<template>
  <q-page class="row">
    <div v-for="image in imagesWithMetadata" v-bind:key="image.image" class="q-pa-xs col-xs-12 col-sm-6 col-md-3 col-lg-2">
      <q-card>
        <q-card-section class="text-left">
          <div class="test-h6">{{ image.name }}</div>
          <div class="text-subtitle2">{{ image.author }}</div>
        </q-card-section>
        <q-card-section class="flex flex-center">
          <q-img 
            :src="image.url" 
            style="height:200px" 
            ratio="2">
          </q-img>
        </q-card-section>
        <q-card-actions>
          <q-btn flat>Mint NFT</q-btn>
        </q-card-actions>
      </q-card>
    </div>
  </q-page>
</template>
<style>
</style>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const storage = getStorage()

export default {
  name: 'Landing',
  data() {
    return {
      imagesWithMetadata: [
        {
          image: 'angry-cat.png',
          name: 'Angry Cat',
          author: 'Lexi',
          url: null
        },
        {
          image: 'car.png',
          name: 'Car',
          author: 'Lexi',
          url: null
        },
        {
          image: 'dog.png',
          name: 'Dog',
          author: 'Lexi',
          url: null
        },
        {
          image: 'happy-cat.png',
          name: 'Happy Cat',
          author: 'Lexi',
          url: null
        },
        {
          image: 'people.png',
          name: 'People',
          author: 'Lexi',
          url: null
        },
        {
          image: 'uncertainty.png',
          name: 'Uncertainty',
          author: 'Lexi',
          url: null
        }
      ]
    }
  },
  methods: {
    iterateImages: function() {
      this.imagesWithMetadata.forEach(function(item) {
        // console.log(item.name)
        getDownloadURL(ref(storage, item.image))
        .then((url) => {
          item.url = url
        })
      })
    }
  },
  mounted() {
    this.iterateImages()
  }
}
</script>
